import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import { faPlusCircle, faMinusCircle, faLongArrowLeft } from '@fortawesome/pro-light-svg-icons'
import {library} from "@fortawesome/fontawesome-svg-core";

library.add(faPlusCircle, faMinusCircle, faLongArrowLeft)

export default {
  install(Vue) {
    Vue.component('font-awesome-icon', FontAwesomeIcon)
  }
}