<template>
  <div class="hello">
    <mdb-container class="py-5">
      <mdb-row>
        <mdb-col sm="12" lg="6" class="mx-auto">
          <img src="@/assets/santa.jpg" alt="Santa" class="img-fluid">
        </mdb-col>
      </mdb-row>
      <mdb-row v-show="!confirmed">
        <mdb-col sm="12" lg="6" class="mx-auto py-4 px-4">
          <mdb-select
              class="mx-auto h3 text-danger"
              v-model="selectData"
              placeholder="What's your name?"
              :visibleOptions="10"
              caretClass="caret-style"
              @getValue="showSecretSanta"
              @toggleSelect="selectFocused"
          />
        </mdb-col>
      </mdb-row>
      <mdb-row v-show="confirmed" class="pt-5">
        <mdb-col col="12">
          <h4>
            You are the Secret Santa for:
          </h4>
          <mdb-spinner small class="mt-3" v-show="!confirmed" />
          <i class="text-danger font-weight-bold pt-3 h2" v-show="confirmed">
            {{yourSanta}}
          </i>
        </mdb-col>
      </mdb-row>
      <mdb-row class="pt-5 px-4 text-center" v-show="!showGifts">
        <mdb-col>
          <p class="h6 text-danger line-height-increase">
            <u @click="showGifts = true" class="text-primary pointer" v-if="false"><i>Add/look at some gift ideas</i></u>
          </p>
        </mdb-col>
      </mdb-row>
      <mdb-row v-show="showGifts">
        <mdb-col sm="12" lg="8" class="pt-5 mx-auto">
          <h2 class="text-primary font-weight-bold py-2"><i>Gift Ideas</i></h2>
        </mdb-col>
        <mdb-col sm="12" lg="8" v-for="(name, index) in filteredGiftData" :key="index" class="pb-5 mx-auto">
          <h4 class="text-left ml-3 text-danger mt-4">Ideas for {{ name.text }}</h4>
          <mdb-row>
            <mdb-col col="10">
              <mdb-input class="m-0 p-0" placeholder="Add Gift Idea" type="text" v-model="giftIdea[index]"></mdb-input>
            </mdb-col>
            <mdb-col col="2"><font-awesome-icon :icon="['fal', 'plus-circle']" class="text-primary h2 p-0 m-0 mt-2 mr-3 pointer" @click="addGiftIdea(name.text, giftIdea[index])"></font-awesome-icon></mdb-col>
          </mdb-row>
          <mdb-row v-for="(gift, index) in name.gifts" :key="index">
            <mdb-col col="10"><p class="h5 text-primary capitalize-text mt-3">{{ gift.idea }}</p></mdb-col>
            <mdb-col col="2"><font-awesome-icon :icon="['fal', 'minus-circle']" class="text-danger h4 p-0 m-0 mt-3 mr-3 pointer" @click="deleteGiftIdea(name.text, index)"></font-awesome-icon></mdb-col>
          </mdb-row>
        </mdb-col>
      </mdb-row>
      <mdb-modal :show="confirm" @close="confirm = false" size="sm" class="text-center">
        <mdb-modal-body>
          <p class="heading text-danger h5-responsive">Are you sure your name is <b class="font-weight-bold">{{yourName}}</b>?</p>
        </mdb-modal-body>
        <mdb-modal-footer center>
          <mdb-btn outline="danger" @click="confirm = false; confirmed = true; showGifts = true" class="w-50 px-0">Pretty sure</mdb-btn>
          <mdb-btn color="danger" @click="confirm = false" class="w-25 px-0">Whoops</mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </mdb-container>
    </div>
</template>

<script>
import {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbInput,
    mdbSpinner
    } from 'mdbvue'
import db from '@/firebase/init'
export default {
  name: 'Home',
  components:{
    mdbContainer,
    mdbSelect,
    mdbRow,
    mdbCol,
    mdbModal,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbInput,
    mdbSpinner
  },
  data () {
    return {
      selectData: [
        { text: 'Frank', value: '1' },
        { text: 'Eva', value: '2' },
        { text: 'Nelson', value: '3' },
        { text: 'Joan', value: '4' },
        { text: 'Chris', value: '5' },
        { text: 'Jill', value: '6' },
        { text: 'Brian', value: '7' },
        { text: 'Megan', value: '8' },
        { text: 'Jeff', value: '9' },
        { text: 'Shannon', value: '10' },
      ],
      giftData: [
        { text: 'Frank', value: '1', gifts: [] },
        { text: 'Eva', value: '2', gifts: [] },
        { text: 'Nelson', value: '3', gifts: [] },
        { text: 'Joan', value: '4', gifts: [] },
        { text: 'Chris', value: '5', gifts: [] },
        { text: 'Jill', value: '6', gifts: [] },
        { text: 'Brian', value: '7', gifts: [] },
        { text: 'Megan', value: '8', gifts: [] },
        { text: 'Jeff', value: '9', gifts: [] },
        { text: 'Shannon', value: '10', gifts: [] },
      ],
      nameOptions: [
        { text: 'Frank', value: '1', couple: 1, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Eva', value: '2', couple: 1, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Nelson', value: '3', couple: 2, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Joan', value: '4', couple: 2, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Chris', value: '5', couple: 3, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Jill', value: '6', couple: 3, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Brian', value: '7', couple: 4, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Megan', value: '8', couple: 4, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Jeff', value: '9', couple: 5, assigned: false, hasSanta: false, santaFor: '' },
        { text: 'Shannon', value: '10', couple: 5, assigned: false, hasSanta: false, santaFor: '' },
      ],
      yourSanta: '',
      yourName: '',
      confirm: false,
      confirmed: false,
      showGifts: false,
      giftIdea: ['','','','','','','','','','']
    };
  },
  methods: {
    showSecretSanta(value, text){
      this.confirm = true
      this.yourName = text
      let santa = this.nameOptions.findIndex(index => index.value === value )
      if(this.nameOptions[santa].assigned === true){
        this.yourSanta = this.nameOptions[santa].santaFor
      } else {
        var unsortedArray = []
        unsortedArray = this.nameOptions.slice()
        var newArray = unsortedArray.filter(name =>{
          if (1 <= value && value <= 4){
            return name.couple !== 1 && name.couple !== 2 && name.hasSanta === false
          } else if(this.oldPeopleLeft.length > 0) {
            return (name.couple === 1 || name.couple === 2) && name.hasSanta === false
          } else {
            return name.text !== text &&
                name.hasSanta === false &&
                name.couple * 2 !== parseInt(value) &&
                name.couple * 2 !== parseInt(value) + 1
          }
        });
        for (let i = newArray.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }
        this.yourSanta = newArray[0].text

        this.nameOptions[santa].assigned = true
        this.nameOptions[santa].santaFor = newArray[0].text

        let newGiftee = this.nameOptions.findIndex(index => index.text === newArray[0].text)
        this.nameOptions[newGiftee].hasSanta = true

        var docData = {
          santaLists: this.nameOptions
        }
        db.collection('santas').doc('2023').set(docData, {merge: true})
      }
    },
    addGiftIdea(name, gift){
      let giftee = this.giftData.findIndex(index => index.text === name )
      this.giftData[giftee].gifts.push({ idea: gift })
      var docData = {
        giftLists: this.giftData
      }
      db.collection('santas').doc('2023').set(docData, {merge: true})
      this.giftIdea = ['','','','','','','','','','']
    },
    deleteGiftIdea(name, index){
      let giftee = this.giftData.findIndex(index => index.text === name)
      this.giftData[giftee].gifts.splice(index, 1)
      var docData = {
        giftLists: this.giftData
      }
      db.collection('santas').doc('2023').set(docData, {merge: true})
    },
    selectFocused(){
      this.confirmed = false
    }
  },
  computed: {
    oldPeopleLeft(){
      return this.nameOptions.filter(santa => {
        return (santa.couple === 1 || santa.couple === 2) && santa.hasSanta === false
      })
    },
    filteredGiftData(){
      return this.giftData.filter(gifts => {
        return gifts.text === this.yourName
          || gifts.text === this.yourSanta
      })
    }
  },
  created(){
    let santaRef = db.collection('santas').doc('2023')

    santaRef.onSnapshot(doc => {
      if(doc.data().santaLists){
        this.nameOptions = doc.data().santaLists
      }
      this.giftData = doc.data().giftLists
    })


    // let giftRef = db.collection('santas').doc('2023').collection('giftLists')

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
.md-form input:not([type]), .md-form input[type="text"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="search"]:not(.browser-default), .md-form input[type="phone"]:not(.browser-default), .md-form input[type="search-md"], .md-form textarea.md-textarea {
  font-size: 1.4rem;
  text-align: center;
  /*border-bottom: none;*/
  font-weight: 300;
}
.dropdown-content li > a, .dropdown-content li > span {
  font-size: 1.2rem;
  color: #0e3d67;
  font-weight: 300;
  text-align: center;
}
.caret-style {
  font-size: 0.6em !important;
  padding-top: 5px;
  color: #ba180f !important;
}

.line-height-increase {
  line-height: 2em;
}

.capitalize-text {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}
</style>
