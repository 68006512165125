import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import SantaPlugins from './plugins/santa-plugins'

Vue.config.productionTip = false

import Notify from 'mdbvue/lib/components/Notify'
Vue.use(Notify)
Vue.use(SantaPlugins)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
